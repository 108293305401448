import React from 'react'

import SEO from '../components/seo'
import Container from '../components/container'
import Header from '../components/header'
import Pages from '../enums/pages'
import Wrapper from '../components/centered-wrapper'
import Background, { Raised } from '../components/background';

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
    <Header page={Pages.NotFound} />
    <Wrapper>
      <Background raised={Raised.Left} offset={0} />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Wrapper>
  </Container>
)

export default NotFoundPage
